@import "~src/theme/scss-colors";

.item.form-entry {
	color: #000;
	padding: 0.25rem 0;
	--border-width: 1px;
	--border-color: #{$color-lightgray};
	// --border-radius: 0 0.5rem 0 0.5rem;

	ion-input {
		color: black;
		margin: -1rem 0 -0.5rem 0;
	}

	ion-label {
		text-transform: uppercase;
		font-weight: 600;
		--color: #{$color-lightgray};
	}
}

.item.form-entry.readonly {
	--border-color: transparent;

	// These lines would align the text all the way to the left, although Lauren prefers the padding to stay in there.
	// --padding-start: 0;
	// --padding-end: 0;

	ion-label,
	.select-disabled {
		opacity: 1;
	}
}

.double-decker-input {
	margin: 0.25rem auto;
}

.double-decker {
	color: #{$color-lightgray};
	margin-top: -1px; // pulls up bottom to prevent double border
	--border-width: 1px;
	--border-color: #{$color-lightgray};

	ion-input {
		color: black;
		margin: -1rem 0 -0.5rem 0;
	}

	ion-label {
		text-transform: uppercase;
		font-weight: 600;
	}
}

.no-border {
	--border-width: 0;
}

form h4 {
	text-transform: uppercase;
	font-weight: 600;
	padding-left: 1rem;
	font-size: 0.9rem;
}

.two-items {
	display: flex;

	.form-entry {
		padding: 0.5rem 0;
	}

	.first {
		padding-right: 0.25rem;
	}
	.second {
		padding-left: 0.25rem;
	}
}

.two-items-header {
	display: flex;

	.second {
		flex: right;
		margin-left: 5.25rem;
	}
}

.confirm-section {
	margin-top: -1rem;
}

.confirm-button {
	float: right;
	color: #{$color-darkblue};
	--border-width: 1px;
	--border-color: #{$color-darkblue};
	// --border-radius: 0 0.5rem 0 0.5rem;
	// margin-bottom: 5rem;
	// font-weight: 600;
}

.form-wrapper {
	overflow: hidden;
}

// ion-item {
//     --border-color: #{$color-lightgray};
// }

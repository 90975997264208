
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~src/theme/scss-colors";

/* Form stylings */
@import "theme/scss-form-stylings.scss";

// Montserrat
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@font-face {
	font-family: "Montserrat";
	src: url("~src/assets/fonts/Montserrat-Light.ttf");
	font-weight: 300;
}
// @font-face {
// 	font-family: "Montserrat";
// 	src: url('assets/fonts/Montserrat-LightItalic.ttf');
// 	font-weight: 300;
// 	font-style: italic;
// }
@font-face {
	font-family: "Montserrat";
	src: url("~src/assets/fonts/Montserrat-Regular.ttf");
	font-weight: 400; //normal
}
@font-face {
	font-family: "Montserrat";
	src: url("~src/assets/fonts/Montserrat-Italic.ttf");
	font-weight: 400; //normal
	font-style: italic;
}
@font-face {
	font-family: "Montserrat";
	src: url("~src/assets/fonts/Montserrat-Medium.ttf");
	font-weight: 500; //medium
}
@font-face {
	font-family: "Montserrat";
	src: url("~src/assets/fonts/Montserrat-SemiBold.ttf");
	font-weight: 600;
}
// @font-face {
// 	font-family: "Montserrat";
// 	src: url('assets/fonts/Montserrat-SemiBoldItalic.ttf');
// 	font-weight: 600;
// 	font-style: italic;
// }
@font-face {
	font-family: "Montserrat";
	src: url("~src/assets/fonts/Montserrat-Bold.ttf");
	font-weight: 700; //bold
}
@font-face {
	font-family: "Montserrat";
	src: url("~src/assets/fonts/Montserrat-BoldItalic.ttf");
	font-weight: 700; //bold
	font-style: italic;
}
@font-face {
	font-family: "Montserrat";
	src: url("~src/assets/fonts/Montserrat-ExtraBold.ttf");
	font-weight: 800;
}
// @font-face {
// 	font-family: "Montserrat";
// 	src: url('assets/fonts/Montserrat-ExtraBoldItalic.ttf');
// 	font-weight: 800;
// 	font-style: italic;
// }

// * {
// 	font-family: var(--ion-font-family);
// }

ion-button:not(.button-clear) {
	font-size: 0.95rem;
	// font-weight: normal;
	text-transform: uppercase;
	height: 3rem;
	text-align: center;
	color: $color-darkblue;
	--border-color: #{$color-darkblue};

	&.blue {
		color: #fff;
		--background: #{$color-darkblue};
		--border-width: 0;
		--color: #fff;
	}
	--padding-start: 1.5rem;
	--padding-end: 1.5rem;
}

ion-button.button-no-border {
	--border-width: 0;
}

.gradient-background {
	--background: url("~src/assets/images/gradient.png") no-repeat bottom / cover #fff;
	--color: white;
	background: url("~src/assets/images/gradient.png") no-repeat bottom / cover #fff;
	color: white;
}

ion-icon[name=ellipsis-horizontal-sharp] {
	font-size: 1.5rem;
}

//for time picker

.timePicker {
	.picker-opts {
		max-width: 68px;
	}
	ion-picker-column {
		&:first-of-type {
			justify-content: flex-end;
		}
		&:last-of-type {
			justify-content: flex-start;
		}
	}
}

ion-searchbar .searchbar-input {
	border: 1px solid #a5a5a5 !important;
	font-size: 0.9rem !important;
	font-weight: bold !important;

	&::placeholder {
		font-size: 0.85rem;
		color: #c3c3c3 !important;
		opacity: 1 !important;
		font-weight: medium !important;
		font-family: "Montserrat", sans-serif;
	}

	@media (min-width: 1120px) {
		&::placeholder {
			font-size: 1.2rem !important;
		}
		
		border-radius: 100px !important;
		padding-top: 12px !important;
		padding-bottom: 12px !important;
		font-size: 1.2rem !important;
		font-weight: 100 !important;
		box-shadow: none !important;
	}
}

.searchbar-search-icon.sc-ion-searchbar-md {
	@media (min-width: 1120px) {
		top: 50%;
		transform: translateY(-50%);
	}
}

.top.no-header {
	ion-img {
		&.american-coach-logo {
			width: 187px;
			display: block;
			margin: 0 auto;
		}
		&.px-logo {
			width: 288px;
			display: block;
			margin: 0 auto;
		}
	}
}

ion-list.transparent-list {
	background: transparent;
	ion-item {
		--background: transparent;
	}
}

.ion-modal {
	padding-top: 5rem;
	--border-radius: 2rem 2rem 0 0;

	@media screen and (min-width: 768px) {
		--border-radius: 0.625rem;
	}
	
	@media screen and (min-width: 1200px) {
		padding-left: 400px;
	}

	.modal-wrapper {
		box-shadow: 0 -5px 9px rgba(0, 0, 0, 0.25);
	}
	&::part(content) {
		opacity: 1;
	}
}
.main-menu-modal {
	padding-top: 0;
	--height: 100%;
	--width: 100%;
}

.delete-button {
	span {
		color: red;
	}
}

ion-footer {
	ion-toolbar {
		border-top: 0;
		padding: 0.5rem 0;
	}
}
ion-content.transparent {
	--background: transparent;
	--color: white;
}

//weird border bottom for header
.header-md::after {
	display: none;
}

//map links
.gmnoprint {
	bottom: 3rem !important;
}

.gmnoprint:nth-of-type(1) {
	right:  240px !important;
}
.gmnoprint:nth-of-type(2) {
	right:  115px !important;
}
.gmnoprint:nth-of-type(3) {
	right:  40px !important;
}


//calendar color for ion-date
input::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

//fix ion modal height
ion-modal {
	.ion-page {
		display: block;
	}
}

.loading {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	margin-left: 1rem;
  }
  .loading:after {
	content: " ";
	display: block;
	width: 0.75rem;
	height: 0.75rem;
	margin: 1px;
	border-radius: 50%;
	border: 2px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: loading 1.2s linear infinite;
  }
  @keyframes loading {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }


  .radio-inputs {
	display: inline-block;
	padding-left: 0;
	label {
		display: inline !important;
	}
	li {
		text-align: left;
		list-style-type: none;
		margin-bottom: 1rem;
		label {
			margin-left: 0.75rem;
		}
		input {
			border-color: #ffffff;
		}
	}

	// input[type='radio']:after {
	// 	width: 10px;
	// 	height: 10px;
	// 	border-radius: 10px;
	// 	top: -2px;
	// 	left: -1px;
	// 	position: relative;
	// 	background-color: #eeeeee;
	// 	content: '';
	// 	display: inline-block;
	// 	visibility: visible;
	// 	border: 2px solid black;
	// }
	

	// input[type='radio']:checked:after {
	// 	width: 10px;
	// 	height: 10px;
	// 	border-radius: 10px;
	// 	top: -2px;
	// 	left: -1px;
	// 	position: relative;
	// 	background-color: #444444;
	// 	content: '';
	// 	display: inline-block;
	// 	visibility: visible;
	// 	border: 2px solid black;
	// }
	&.grid-2x2 {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
}
.margin-bottom-15 {
	margin-bottom: 15rem;
}

.card.dark {
	background: #212121;
	color: white;
}

.card {
	border-radius: 10px;
	list-style: none;
	background: white;
	color: $primary;
	padding: 1.75rem;
	> *:first-child {
		margin-top: 0;
	}
}

ion-popover {
	--background: rgba(0,0,0,0);
	--background-opacity: 0.6;
	&::part(content) {
		left: 0 !important;
	}
}

ion-popover.date-popover {
	--width: 100% !important;
	&::part(content) {
		top: 30% !important;
	}
	ion-datetime {
		border-radius: 1rem;
		box-shadow:rgba(0, 0, 0, 0.25);
		margin: 0 auto;
	}
}

#save-profile {
	margin-bottom: 2rem;
}
.ngxImageZoomContainer {
	width: 100% !important;
	height: auto !important;
}


.vin-input input {
	// width: 100% !important;
	text-align: center !important;
	&[disabled] {
		color: white;
		text-decoration: none !important;
	}
}

.main-menu-modal::part(content) {
	transform: translate(0);
}

ion-list.transparent {
	background: transparent !important;
	ion-item {
		--background: transparent !important;
	}
}

ion-content::part(background) {
	top: calc((var(--offset-top) * -1) - 1px);
}

.sc-ion-alert-md-h {
	@media screen and (min-width: 1200px) {
		padding-left: 400px;
	}
}
